#dropdown-menu {
    min-width: 180px !important;
    overflow-wrap: break-word;
}

#dropdown-menu div[role="option"].item {
    text-wrap: balance;
}

#dropdown-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}