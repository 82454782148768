body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: #4d7198;
  --header-background-color: rgb(31 117 168 / 86%);
  --header-color: white;
}

h2.blue.header.ui {
  color: var(--color-primary) !important;
}

.cron_builder {
  width: 100% !important;
}

#root {
  height: 100vh;
}

.shadow {
  box-shadow: 0px 2px 3px 0px #00000026 !important;
}

.no-left-right-margin {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

@media (min-width: 1400px) {
  .test {
      background-color: red !important;
      width: 50px !important;
  }
}

.fix-height {
  height: 37.57px !important;
}